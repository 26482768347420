@import "bulma";

body {
  font-size: 1.1em;
  line-height: 1.5;
}

h1,h2,h3,h4,h5,h6 {
  font-family: $family-serif;
}
p, span, ul, li, {
  font-family: $family-sans-serif;
}

.sans {
  font-family: $family-sans-serif !important;
}

.title {
  font-weight: 700;
}

.container, .section .columns {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.container {
  height: 75vh;
  padding: .9375rem 1.875rem;
}

.is-v-centered {
  display: flex;
  align-items: center;
}

.text-center {
  text-align: center;
}

.is-outline-button {
  color: #fff;
  background-color: transparent;
  border: 3px solid #fff;
  &:hover {
    color: #fff;
  }
}

.secondary-bg {
  background-color: $grey-light;
}

.primary-color-bg {
  background-color: $primary;
}

.info-color-bg {
  background-color: $info;
}

.ml-auto{
  margin-left: auto;
}

strong.is-danger {
  color: $danger;
}


// component styling
@import "layouts/index";
@import "pages/index";
@import "components/index";
