@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400&display=swap');

// Set your brand colors
$primary: #4274B1;
$info: #2E4C8D;
$link: $primary;
$danger: #c7392e;
$grey-light: #F9FAFC;

// Update Bulma's global variables
$family-serif: 'Source Serif Pro', serif;
$family-sans-serif: 'Rubik', sans-serif;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: #fff;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;


// Import only what you need from Bulma
@import '../../../node_modules/bulma/sass/utilities/all';
@import '../../../node_modules/bulma/sass/base/all';
@import '../../../node_modules/bulma/sass/form/all';
@import '../../../node_modules/bulma/sass/elements/all';
@import '../../../node_modules/bulma/sass/components/all';
@import '../../../node_modules/bulma/sass/layout/all';
@import '../../../node_modules/bulma/sass/grid/all';

