
.hero-search {
  background: linear-gradient(rgba($primary, .85), rgba($primary, .85)), url('../../images/bg.png');
  background-repeat: no-repeat;
  background-size: cover;

  h1, h2 {
    margin-bottom: 1rem;
  }

  h1 {
    font-size: 2.5rem;
    line-height: 1;
    font-weight: 700;
    color: #fff;
  }

  h2 {
    font-size: 1.35rem;
    line-height: 1.2;
    font-weight: 400;
    font-family: $family-sans-serif;
    color: #fff;
    strong {
      color: #fff;
    }
  }

  .email-form {
    max-width: 40rem;

    .is-light {
      color: $primary;
      font-weight: 600;
    }

    form {
      margin-top: 0.9375rem;
      margin-bottom: 0.9375rem;
      div, input, button {
        margin-bottom: 0.9375rem;
      }
      label {
        color: #fff !important;
        font-size: 22px;
        input {
          margin-right: 6px;
        }
      }

    }
  }
}

.cta-banner {
  @media screen and (min-width: $tablet) {
    text-align: center;
  }

  .title {
    color: #fff;
  }
}




